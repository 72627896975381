import React from "react";
import styled from "styled-components";
import { colors } from "../../theme";
import Resultados1 from "./Resultados1";
import Resultados2 from "./Resultados2";
import Resultados3 from "./Resultados3";
import Resultados4 from "./Resultados4";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  margin-left: 11.05vw;
  position: absolute;
`;

const DivText = styled.div`
  color: ${colors.third};
  margin-top: 10vh;
  border-bottom: 1px solid ${colors.fourth};
  width: 80vw;
`;
const DivTextDetails = styled.div`
  color: ${colors.black};
  margin-top: 3vh;
  width: 80vw;
  text-align: center;
`;
const DivTextBar = styled.div`
  color: ${colors.third};
  margin-top: 3vh;
  width: 40vw;
  text-align: center;
`;

const DivFlex = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;
const ButtonReturn = styled.button`
  height: ${(props) => props.customHeight || "2.5vh"};
  width: ${(props) => props.customWidth || "2vw"};
  background-color: ${colors.gray};
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 5px;
  justify-content: center;
  font-size: 1.5rem;
  align-items: center;
  margin-top: 20px;
`;

const DivGroup = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Resultados = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <ContentWrapper>
        <DivText>
          <h1>Relatórios</h1>
        </DivText>
        <ButtonReturn
          customWidth="6vw"
          customHeight="4vh"
          onClick={() => navigate("/plataforma/")}
        >
          Voltar
        </ButtonReturn>

        <DivTextDetails>
          <h1>Sobre a Empresa</h1>
        </DivTextDetails>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Possui Responsáveis Técnicos Substitutos?</h2>
            </DivTextBar>
            <Resultados1 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Possui mais de uma unidade?</h2>
            </DivTextBar>
            <Resultados2 />
          </DivGroup>
        </DivFlex>

        <DivTextDetails>
          <h1>Modalidades</h1>
        </DivTextDetails>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Musculação / Treinamento Resistido</h2>
            </DivTextBar>
            <Resultados3 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Dança</h2>
            </DivTextBar>
            <Resultados4 />
          </DivGroup>
        </DivFlex>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Indoor Cycle / Bike Indoor</h2>
            </DivTextBar>
            <Resultados2 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>CrossFit</h2>
            </DivTextBar>
            <Resultados4 />
          </DivGroup>
        </DivFlex>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Artes Maciais / Lutas</h2>
            </DivTextBar>
            <Resultados3 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Treinamento Funcional</h2>
            </DivTextBar>
            <Resultados1 />
          </DivGroup>
        </DivFlex>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Localizada / Alogamento</h2>
            </DivTextBar>
            <Resultados1 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Pilates</h2>
            </DivTextBar>
            <Resultados4 />
          </DivGroup>
        </DivFlex>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Atividades Aquática / Natação</h2>
            </DivTextBar>
            <Resultados2 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Ballet</h2>
            </DivTextBar>
            <Resultados1 />
          </DivGroup>
        </DivFlex>

        <DivFlex>
          <DivGroup>
            <DivTextBar>
              <h2>Yoga</h2>
            </DivTextBar>
            <Resultados1 />
          </DivGroup>

          <DivGroup>
            <DivTextBar>
              <h2>Outro Tipo de Modalidade</h2>
            </DivTextBar>
            <Resultados4 />
          </DivGroup>
        </DivFlex>
      </ContentWrapper>
    </Container>
  );
};

export default Resultados;
