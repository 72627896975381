import React, { useState } from "react";

import {
  DivContet,
  DivFlex,
  DivText,
  Input,
  Select,
  FormGroup,
  Label,
  CheckboxInput,
  Container,
  ContentWrapper,
  ButtonSearch,
  ButtonReturn,
} from "./styles";
import { useNavigate } from "react-router-dom";

const DadosEmpresa = () => {
  const [nome, setNome] = useState("");
  const [profissao, setProfissao] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [logadouro, setLogadouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [uf, setUf] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [meuinss, setMeuInss] = useState("");
  const [possuiResposavel, setPossuiResponsavel] = useState(false);
  const [possuiUnidade, setPossuiUnidade] = useState(false);
  const [musculação, setMsculação] = useState(false);
  const [dança, setDança] = useState(false);
  const [indoor, setIndoor] = useState(false);
  const [crossfit, setCrossfit] = useState(false);
  const [lutas, setLutas] = useState(false);
  const [funcional, setFuncional] = useState(false);
  const [localizada, setLocalizada] = useState(false);
  const [pilates, setPilates] = useState(false);
  const [natação, setNatação] = useState(false);
  const [ballet, setBallet] = useState(false);
  const [yoga, setYoga] = useState(false);
  const [outroTipo, setOutroTipo] = useState(false);
  const [realizaExames, setRealizaExames] = useState(false);
  const [anamnese, setAnamnese] = useState(false);
  const [parq, setParq] = useState(false);
  const [dobrasCultaneas, setDobrasCultaneas] = useState(false);
  const [Bioimpedancia, setBioimpedancia] = useState(false);
  const [antroOutroTipo, setAntroOutroTipo] = useState(false);
  const [testesFuncionais, setTestesFuncionais] = useState(false);
  const [avaliaçãoPostural, setAvaliaçãoPostural] = useState(false);
  const [avaliaçãoNeuromuscular, setAvaliaçãoNeuromuscular] = useState(false);
  const [avaliaçãoCardiorespiratoria, setAvaliaçãoCardiorespiratoria] =
    useState(false);
  const [avaliaçãoFlexibilidade, setAvaliaçãoFlexibilidade] = useState(false);
  const [realizaReuniões, setRealizaReuniões] = useState(false);
  const [frequenciaSemanal, setFrequenciaSemanal] = useState(false);
  const [frequenciaQuinzenal, setFrequenciaQuinzenal] = useState(false);
  const [frequenciaMensal, setFrequenciaMensal] = useState(false);
  const [pautasEspecificas, setPautasEspecificas] = useState(false);
  const [sugestõesCriticas, setSugestõesCriticas] = useState(false);
  const [possuiCanalComu, setPossuiCanalComu] = useState(false);
  const [realizaCapacitacoes, setRealizaCapacitacoes] = useState(false);
  const [registraOpniões, setRegistraOpniões] = useState(false);
  const [recebeAcademicos, setRecebeAcademicos] = useState(false);
  const [quantosEstagiariosExtra, setQuantosEstagiariosExtra] = useState("");
  const [SolicitadoAcadeExtra, setSolicitadoAcadeExtra] = useState(false);
  const [analisaERealizaModi, setAnalisaERealizaModi] = useState(false);
  const [realizaInspecoes, setRealizaInspecoes] = useState(false);
  const [possuiRegistroDasInspecoes, setPossuiRegistroDasInspecoes] =
    useState(false);

  const [realizaSupervisaoExecu, setRealizaSupervisaoExecu] = useState(false);
  const [possuiRegistroSupervisaoExecu, setPossuiRegistroSupervisaoExecu] =
    useState(false);

  const [zelaPeloComprimento, setZelaPeloComprimento] = useState(false);

  const navigate = useNavigate();

  return (
    <Container>
      <ContentWrapper>
        <DivText>
          <h1>Dados Empresa</h1>
        </DivText>

        <DivContet>
          <h2>
            VERACIDADE NA INSERÇÃO DOS DADOS
            <br />
            <br />
            Faz-se importante mencionar que de acordo com o Código Penal -
            Decreto-Lei no 2.848, de 7 de dezembro de 1940, incluído pela Lei nº
            9.983, de 2000 (Inserção de dados falsos em sistema de informações)
            no artigo 313-A do Código Penal prevê o crime de inserção de dados
            falsos em sistema de informações, também chamado de peculato
            digital. Com pena prevista vai de 2 a 12 anos de prisão e multa.
            <br />
            <br />
          </h2>
          <DivFlex>
            <FormGroup>
              <Label>Razão Social:</Label>
              <Input
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                customWidth="25vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>CNPJ:</Label>
              <Input
                type="text"
                value={profissao}
                onChange={(e) => setProfissao(e.target.value)}
                customWidth="16vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Nº Registro CREF15/PI:</Label>
              <Input
                type="text"
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Nome do Responsável Legal:</Label>
              <Input
                type="text"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                customWidth="16vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>CPF do Responsável Legal:</Label>
              <Input
                type="text"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>CEP:</Label>
              <Input
                type="text"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Logradouro:</Label>
              <Input
                type="text"
                value={logadouro}
                onChange={(e) => setLogadouro(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Complemento:</Label>
              <Input
                type="text"
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Bairro:</Label>
              <Input
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                customWidth="8vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Estado:</Label>
              <Input
                type="text"
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                customWidth="10vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>UF:</Label>
              <Input
                type="text"
                value={uf}
                onChange={(e) => setUf(e.target.value)}
                customWidth="4vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Telefone 01:</Label>
              <Input
                type="text"
                value={telefone1}
                onChange={(e) => setTelefone1(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Telefone 02:</Label>
              <Input
                type="text"
                value={telefone2}
                onChange={(e) => setTelefone2(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>WhatsApp:</Label>
              <Input
                type="text"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Endereço Eletrônico do Responsável Legal:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="19vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Endereço Eletrônico:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="14vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Email:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="16vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <h2>
              Uplaoad do Comprovante de Pagamento de Anuiade do Corrente Ano de
              Estabelecimento Junto ao CREF:
            </h2>
          </FormGroup>

          <DivFlex>
            <FormGroup>
              <Label>2024-2025</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>2023-2024</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>2022-2023</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <h2>Horario de Funcionamento do Estabelecimento</h2>
          </FormGroup>

          <DivFlex>
            <FormGroup>
              <FormGroup>
                <h3>Matutitno (00H - 12H)</h3>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <Label>Segunda:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Terça:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quarta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quinta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sexta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sábado:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Domingo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <FormGroup>
                <h3>Vespertino (12H - 18H)</h3>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <Label>Segunda:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Terça:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quarta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quinta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sexta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sábado:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Domingo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <FormGroup>
                <h3>Noturno (18H - 00H)</h3>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <Label>Segunda:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Terça:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quarta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Quinta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sexta:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
                <DivFlex>
                  <Label>Sábado:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>

                <DivFlex>
                  <Label>Domingo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                  <Label>às</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="3vw"
                  />
                </DivFlex>
              </FormGroup>
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <h2>Responsável Técnico da Empresa</h2>
          </FormGroup>

          <DivFlex>
            <FormGroup>
              <Label>Nome Do Responsável Técnico Titular:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="19vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Nº Registro CREF15/PI:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="9vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Dias da Semana Trabalhados:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Horário de Trabalho:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="9vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Carga Horária Semanal:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="10vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Foto do Responsável Técnico:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Contrato de Trabalho Entre a Empresa/Instituição e Responsável
                Técnico:
              </Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <Label>
              Possui Responsáveis Técnicos Substitutos?
              <CheckboxInput
                type="checkbox"
                checked={possuiResposavel}
                onChange={(e) => setPossuiResponsavel(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {possuiResposavel && (
            <DivContet>
              <FormGroup>
                <DivFlex>
                  <FormGroup>
                    <Label>Nome Completo:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="19vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nº Registro CREF15/PI:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Contato com DDD:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>
                      Contrato de Trabalho Entre a Empresa/Instituição e
                      Responsável Técnico:
                    </Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="13vw"
                    />
                  </FormGroup>
                </DivFlex>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <FormGroup>
                    <Label>Nome Completo:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="19vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nº Registro CREF15/PI:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Contato com DDD:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>Registro Profissional:</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="13vw"
                    />
                  </FormGroup>
                </DivFlex>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <FormGroup>
                    <Label>Nome Completo:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="19vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nº Registro CREF15/PI:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Contato com DDD:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>Registro Profissional:</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="13vw"
                    />
                  </FormGroup>
                </DivFlex>
              </FormGroup>

              <FormGroup>
                <DivFlex>
                  <FormGroup>
                    <Label>Nome Completo:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="19vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nº Registro CREF15/PI:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Contato com DDD:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="9vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>Registro Profissional:</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="13vw"
                    />
                  </FormGroup>
                </DivFlex>
              </FormGroup>
            </DivContet>
          )}

          <FormGroup>
            <Label>
              Possui mais de uma unidade?
              <CheckboxInput
                type="checkbox"
                checked={possuiUnidade}
                onChange={(e) => setPossuiUnidade(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {possuiUnidade && (
            <DivContet>
              <FormGroup>
                <DivFlex>
                  <FormGroup>
                    <Label>CEP:</Label>
                    <Input
                      type="text"
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Logradouro:</Label>
                    <Input
                      type="text"
                      value={logadouro}
                      onChange={(e) => setLogadouro(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Complemento:</Label>
                    <Input
                      type="text"
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Bairro:</Label>
                    <Input
                      type="text"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      customWidth="8vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Estado:</Label>
                    <Input
                      type="text"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      customWidth="10vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>UF:</Label>
                    <Input
                      type="text"
                      value={uf}
                      onChange={(e) => setUf(e.target.value)}
                      customWidth="4vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>Telefone 01:</Label>
                    <Input
                      type="text"
                      value={telefone1}
                      onChange={(e) => setTelefone1(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Telefone 02:</Label>
                    <Input
                      type="text"
                      value={telefone2}
                      onChange={(e) => setTelefone2(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>WhatsApp:</Label>
                    <Input
                      type="text"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                </DivFlex>

                <DivFlex>
                  <FormGroup>
                    <Label>Email:</Label>
                    <Input
                      type="text"
                      value={meuinss}
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="16vw"
                    />
                  </FormGroup>
                </DivFlex>
              </FormGroup>

              <FormGroup>
                <h2>Horario de Funcionamento do Estabelecimento</h2>
              </FormGroup>

              <DivFlex>
                <FormGroup>
                  <FormGroup>
                    <h3>Matutitno (00H - 12H)</h3>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <Label>Segunda:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Terça:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quarta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quinta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sexta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sábado:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Domingo:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <FormGroup>
                    <h3>Vespertino (12H - 18H)</h3>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <Label>Segunda:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Terça:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quarta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quinta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sexta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sábado:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Domingo:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <FormGroup>
                    <h3>Noturno (18H - 00H)</h3>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <Label>Segunda:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Terça:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quarta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Quinta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sexta:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                    <DivFlex>
                      <Label>Sábado:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>

                    <DivFlex>
                      <Label>Domingo:</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                      <Label>às</Label>
                      <Input
                        type="text"
                        value={meuinss}
                        onChange={(e) => setMeuInss(e.target.value)}
                        customWidth="3vw"
                      />
                    </DivFlex>
                  </FormGroup>
                </FormGroup>
              </DivFlex>

              <FormGroup>
                <h2>Responsável Técnico da Empresa</h2>
              </FormGroup>

              <DivFlex>
                <FormGroup>
                  <Label>Nome Do Responsável Técnico Titular:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
              </DivFlex>

              <DivFlex>
                <FormGroup>
                  <Label>Dias da Semana Trabalhados:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Horário de Trabalho:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Carga Horária Semanal:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="10vw"
                  />
                </FormGroup>
              </DivFlex>

              <DivFlex>
                <FormGroup>
                  <Label>Foto do Responsável Técnico:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    Contrato de Trabalho Entre a Empresa/Instituição e
                    Responsável Técnico:
                  </Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>

              <FormGroup>
                <Label>
                  Possui Responsáveis Técnicos Substitutos?
                  <CheckboxInput
                    type="checkbox"
                    checked={possuiResposavel}
                    onChange={(e) => setPossuiResponsavel(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>

              {possuiResposavel && (
                <DivContet>
                  <FormGroup>
                    <DivFlex>
                      <FormGroup>
                        <Label>Nome Completo:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="19vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Nº Registro CREF15/PI:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Contato com DDD:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>
                    </DivFlex>

                    <DivFlex>
                      <FormGroup>
                        <Label>
                          Contrato de Trabalho Entre a Empresa/Instituição e
                          Responsável Técnico:
                        </Label>
                        <Input
                          type="file"
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="13vw"
                        />
                      </FormGroup>
                    </DivFlex>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <FormGroup>
                        <Label>Nome Completo:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="19vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Nº Registro CREF15/PI:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Contato com DDD:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>
                    </DivFlex>

                    <DivFlex>
                      <FormGroup>
                        <Label>Registro Profissional:</Label>
                        <Input
                          type="file"
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="13vw"
                        />
                      </FormGroup>
                    </DivFlex>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <FormGroup>
                        <Label>Nome Completo:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="19vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Nº Registro CREF15/PI:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Contato com DDD:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>
                    </DivFlex>

                    <DivFlex>
                      <FormGroup>
                        <Label>Registro Profissional:</Label>
                        <Input
                          type="file"
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="13vw"
                        />
                      </FormGroup>
                    </DivFlex>
                  </FormGroup>

                  <FormGroup>
                    <DivFlex>
                      <FormGroup>
                        <Label>Nome Completo:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="19vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Nº Registro CREF15/PI:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>Contato com DDD:</Label>
                        <Input
                          type="text"
                          value={meuinss}
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="9vw"
                        />
                      </FormGroup>
                    </DivFlex>

                    <DivFlex>
                      <FormGroup>
                        <Label>Registro Profissional:</Label>
                        <Input
                          type="file"
                          onChange={(e) => setMeuInss(e.target.value)}
                          customWidth="13vw"
                        />
                      </FormGroup>
                    </DivFlex>
                  </FormGroup>
                </DivContet>
              )}
            </DivContet>
          )}

          <FormGroup>
            <h2>Modalidades Oferecidas Pela Empresa</h2>
          </FormGroup>

          <FormGroup>
            <Label>
              Musculação / Treinamento Resistido
              <CheckboxInput
                type="checkbox"
                checked={musculação}
                onChange={(e) => setMsculação(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {musculação && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Dança
              <CheckboxInput
                type="checkbox"
                checked={dança}
                onChange={(e) => setDança(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {dança && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Indoor Cycle / Bike Indoor
              <CheckboxInput
                type="checkbox"
                checked={indoor}
                onChange={(e) => setIndoor(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {indoor && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              CrossFit
              <CheckboxInput
                type="checkbox"
                checked={crossfit}
                onChange={(e) => setCrossfit(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {crossfit && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Artes Maciais / Lutas
              <CheckboxInput
                type="checkbox"
                checked={lutas}
                onChange={(e) => setLutas(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {lutas && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Treinamento Funcional
              <CheckboxInput
                type="checkbox"
                checked={funcional}
                onChange={(e) => setFuncional(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {funcional && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Localizada / Alogamento
              <CheckboxInput
                type="checkbox"
                checked={localizada}
                onChange={(e) => setLocalizada(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {localizada && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Pilates
              <CheckboxInput
                type="checkbox"
                checked={pilates}
                onChange={(e) => setPilates(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {pilates && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Atividades Aquática / Natação
              <CheckboxInput
                type="checkbox"
                checked={natação}
                onChange={(e) => setNatação(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {natação && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Ballet
              <CheckboxInput
                type="checkbox"
                checked={ballet}
                onChange={(e) => setBallet(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {ballet && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Yoga
              <CheckboxInput
                type="checkbox"
                checked={yoga}
                onChange={(e) => setYoga(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {yoga && (
            <FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Outro Tipo de Modalidade
              <CheckboxInput
                type="checkbox"
                checked={outroTipo}
                onChange={(e) => setOutroTipo(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {outroTipo && (
            <FormGroup>
              <FormGroup>
                <Label>Especifique:</Label>
                <Input
                  type="text"
                  value={meuinss}
                  onChange={(e) => setMeuInss(e.target.value)}
                  customWidth="19vw"
                />
              </FormGroup>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
              <DivFlex>
                <FormGroup>
                  <Label>Nome Completo:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Nº Registro CREF15/PI:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="9vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Registro Profissional:</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="13vw"
                  />
                </FormGroup>
              </DivFlex>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              <h3>
                A Empresa Realiza ou Solicita Algum Tipo de Avaliação Física dos
                Seus Clientes?
              </h3>
              <CheckboxInput
                type="checkbox"
                checked={realizaExames}
                onChange={(e) => setRealizaExames(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {realizaExames && (
            <DivContet>
              <FormGroup>
                <Label>
                  Anamnese
                  <CheckboxInput
                    type="checkbox"
                    checked={anamnese}
                    onChange={(e) => setAnamnese(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Questionário de Prontidão para Atividade Física (PAR-Q)
                  <CheckboxInput
                    type="checkbox"
                    checked={parq}
                    onChange={(e) => setParq(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Avaliação Antropométrica: Dobras Cutâneas
                  <CheckboxInput
                    type="checkbox"
                    checked={dobrasCultaneas}
                    onChange={(e) => setDobrasCultaneas(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  Avaliação Antropométrica: Bioimpedância
                  <CheckboxInput
                    type="checkbox"
                    checked={Bioimpedancia}
                    onChange={(e) => setBioimpedancia(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Avaliação Antropométrica: Outro Tipo
                  <CheckboxInput
                    type="checkbox"
                    checked={antroOutroTipo}
                    onChange={(e) => setAntroOutroTipo(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>
              {antroOutroTipo && (
                <FormGroup>
                  <Label>Especifique:</Label>
                  <Input
                    type="text"
                    value={meuinss}
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="19vw"
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label>
                  Testes Funcionais
                  <CheckboxInput
                    type="checkbox"
                    checked={testesFuncionais}
                    onChange={(e) => setTestesFuncionais(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  Avaliação Postural
                  <CheckboxInput
                    type="checkbox"
                    checked={avaliaçãoPostural}
                    onChange={(e) => setAvaliaçãoPostural(e.target.checked)}
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Avaliação Cardiorrespiratória
                  <CheckboxInput
                    type="checkbox"
                    checked={avaliaçãoCardiorespiratoria}
                    onChange={(e) =>
                      setAvaliaçãoCardiorespiratoria(e.target.checked)
                    }
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Avaliação Neuromuscular
                  <CheckboxInput
                    type="checkbox"
                    checked={avaliaçãoNeuromuscular}
                    onChange={(e) =>
                      setAvaliaçãoNeuromuscular(e.target.checked)
                    }
                  />
                  Sim
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  Teste de Flexibilidade
                  <CheckboxInput
                    type="checkbox"
                    checked={avaliaçãoFlexibilidade}
                    onChange={(e) =>
                      setAvaliaçãoFlexibilidade(e.target.checked)
                    }
                  />
                  Sim
                </Label>
              </FormGroup>
            </DivContet>
          )}

          <FormGroup>
            <h2>
              MONITORAMENTO DAS ATRIBUIÇÕES LEGAIS DO RESPONSÁVEL TÉCNICO – RT
            </h2>

            <Label>
              As informações contidas nesse formulário atentem a Resolução
              CONFEF nº 477/2023 que dispõe sobre a função de Responsabilidade
              Técnica nos estabelecimentos prestadores de serviços no campo das
              atividades físicas e esportivas.
            </Label>
          </FormGroup>

          <FormGroup>
            <h2>ATRIBUIÇÕES LEGAIS DO RESPONSÁVEL TÉCNICO – RT</h2>
          </FormGroup>

          <FormGroup>
            <Label>
              Monitoramento e supervisão na coordenação das atividades dos
              Profissionais de Educação Física
            </Label>
            <Label>
              Realiza reuniões periódicas com professores, estagiários e demais
              funcionários do estabelecimento?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={realizaReuniões}
              value={"sim"}
              onChange={(e) => setRealizaReuniões(e.target.checked)}
            />
            Sim
          </FormGroup>

          {realizaReuniões && (
            <DivContet>
              <FormGroup>
                <Label>Com qual periodicidade realiza das reuniões?</Label>
                <CheckboxInput
                  type="checkbox"
                  checked={frequenciaSemanal}
                  onChange={(e) => setFrequenciaSemanal(e.target.checked)}
                />
                Semanalmente
                <CheckboxInput
                  type="checkbox"
                  checked={frequenciaQuinzenal}
                  onChange={(e) => setFrequenciaQuinzenal(e.target.checked)}
                />
                Quinzenalmente
                <CheckboxInput
                  type="checkbox"
                  checked={frequenciaMensal}
                  onChange={(e) => setFrequenciaMensal(e.target.checked)}
                />
                Mensalmente
              </FormGroup>
              <FormGroup>
                <Label>Faça o upload do calendário de reuniões.</Label>
                <Input
                  type="file"
                  onChange={(e) => setMeuInss(e.target.value)}
                  customWidth="12vw"
                />
              </FormGroup>

              <FormGroup>
                <Label>As reuniões possuem pautas específicas?</Label>
                <CheckboxInput
                  type="checkbox"
                  checked={pautasEspecificas}
                  onChange={(e) => setPautasEspecificas(e.target.checked)}
                />
                Sim
              </FormGroup>

              {pautasEspecificas && (
                <FormGroup>
                  <FormGroup>
                    <Label>Faça o upload das pautas das reuniões.</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Upload do registro fotográfico.</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                </FormGroup>
              )}
            </DivContet>
          )}

          <FormGroup>
            <Label>
              Realiza capacitações técnicas periódicas para os profissionais
              como professores, estagiários e demais funcionários do
              estabelecimento?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={realizaCapacitacoes}
              onChange={(e) => setRealizaCapacitacoes(e.target.checked)}
            />
            Sim
          </FormGroup>

          {realizaCapacitacoes && (
            <FormGroup>
              <FormGroup>
                <Label>Upload do registro fotográfico</Label>
                <Input
                  type="file"
                  onChange={(e) => setMeuInss(e.target.value)}
                  customWidth="12vw"
                />
              </FormGroup>
              <FormGroup>
                <Label>Acrescentar mais um documento</Label>
                <Input
                  type="file"
                  onChange={(e) => setMeuInss(e.target.value)}
                  customWidth="12vw"
                />
              </FormGroup>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Conversa com os alunos para analisar suas sugestões e críticas?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={sugestõesCriticas}
              onChange={(e) => setSugestõesCriticas(e.target.checked)}
            />
            Sim
          </FormGroup>

          {sugestõesCriticas && (
            <DivContet>
              <FormGroup>
                <Label>Com qual periodicidade?</Label>
                <Select
                  value={quantosEstagiariosExtra}
                  onChange={(e) => setQuantosEstagiariosExtra(e.target.value)}
                  customWidth="14.28vw"
                >
                  <option value="" selected disabled>
                    Selecione
                  </option>
                  <option value="Diariamente">Diariamente</option>
                  <option value="Semanalmente">Semanalmente</option>
                  <option value="Quinzenalmente">Quinzenalmente</option>
                  <option value="Mensalmente">Mensalmente</option>
                  <option value="De acordo com a demanda">
                    De acordo com a demanda
                  </option>
                </Select>
              </FormGroup>
            </DivContet>
          )}

          <FormGroup>
            <Label>
              O estabelecimento possui um canal de comunicação para sugestões e
              críticas dos alunos, como caixa de sugestões?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={possuiCanalComu}
              onChange={(e) => setPossuiCanalComu(e.target.checked)}
            />
            Sim
          </FormGroup>

          <FormGroup>
            <Label>
              O registro das opiniões, sugestões e críticas dos alunos no
              estabelecimento, são realizadas de maneira anônima?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={registraOpniões}
              onChange={(e) => setRegistraOpniões(e.target.checked)}
            />
            Sim
          </FormGroup>

          <FormGroup>
            <Label>
              O estabelecimento recebe acadêmicos de Educação Física para
              estágios extracurriculares?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={recebeAcademicos}
              onChange={(e) => setRecebeAcademicos(e.target.checked)}
            />
            Sim
          </FormGroup>
          {recebeAcademicos && (
            <DivContet>
              <FormGroup>
                <Label>
                  Quantos estagiários extracurriculares o estabelecimento possui
                  no momento?:
                </Label>
                <Select
                  value={quantosEstagiariosExtra}
                  onChange={(e) => setQuantosEstagiariosExtra(e.target.value)}
                  customWidth="14.28vw"
                >
                  <option value="" selected disabled>
                    Selecione
                  </option>
                  <option value="0-3">0-3</option>
                  <option value="4-6">4-6</option>
                  <option value="7-10">7-10</option>
                  <option value="Mais de 10 estagiários extra curricular">
                    Mais de 10 estagiários extra curricular
                  </option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>
                  São solicitados dos acadêmicos do estágio extracurricular:
                  matrícula e frequência regular do educando no período vigente
                  junto a universidade; celebração de termo de compromisso entre
                  o educando, a parte concedente do estágio e a instituição de
                  ensino e documento de compatibilidade entre as atividades
                  desenvolvidas no estágio extracurricular e as previstas no
                  termo de compromisso?
                </Label>
                <CheckboxInput
                  type="checkbox"
                  checked={SolicitadoAcadeExtra}
                  onChange={(e) => setSolicitadoAcadeExtra(e.target.checked)}
                />
                Sim
              </FormGroup>

              {SolicitadoAcadeExtra && (
                <FormGroup>
                  <FormGroup>
                    <Label>
                      Faça o upload dos documentos de cada estagiário
                    </Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Acrescentar mais um documento</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                </FormGroup>
              )}
            </DivContet>
          )}

          <FormGroup>
            <Label>
              Analisa e realiza modificações e inclusões de procedimentos
              periodicamente no estabelecimento?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={analisaERealizaModi}
              onChange={(e) => setAnalisaERealizaModi(e.target.checked)}
            />
            Sim
          </FormGroup>

          {analisaERealizaModi && (
            <FormGroup>
              <FormGroup>
                <Label>
                  Descreva cada um deles com suas respectivas datas:
                </Label>
                <Input
                  type="text"
                  value={profissao}
                  onChange={(e) => setProfissao(e.target.value)}
                  customWidth="16vw"
                />
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <Label>Upload do registro fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Acrescentar mais registros fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  value={profissao}
                  onChange={(e) => setProfissao(e.target.value)}
                  customWidth="16vw"
                />
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <Label>Upload do registro fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Acrescentar mais registros fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  value={profissao}
                  onChange={(e) => setProfissao(e.target.value)}
                  customWidth="16vw"
                />
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <Label>Upload do registro fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Acrescentar mais registros fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  value={profissao}
                  onChange={(e) => setProfissao(e.target.value)}
                  customWidth="16vw"
                />
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <Label>Upload do registro fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Acrescentar mais registros fotográfico</Label>
                  <Input
                    type="file"
                    onChange={(e) => setMeuInss(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>
              </FormGroup>
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Realiza inspeções das condições físicas e tecnológicas para o
              atendimento no estabelecimento?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={realizaInspecoes}
              onChange={(e) => setRealizaInspecoes(e.target.checked)}
            />
            Sim
          </FormGroup>
          {realizaInspecoes && (
            <FormGroup>
              <FormGroup>
                <Label>Com qual periodicidade?</Label>
                <Select
                  value={quantosEstagiariosExtra}
                  onChange={(e) => setQuantosEstagiariosExtra(e.target.value)}
                  customWidth="14.28vw"
                >
                  <option value="" selected disabled>
                    Selecione
                  </option>
                  <option value="Diariamente">Diariamente</option>
                  <option value="Semanalmente">Semanalmente</option>
                  <option value="Quinzenalmente">Quinzenalmente</option>
                  <option value="Mensalmente">Mensalmente</option>
                  <option value="De acordo com a demanda">
                    De acordo com a demanda
                  </option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>
                  Possui um registro das inspeções realizadas das condições
                  físicas e tecnológicas para o atendimento no estabelecimento?
                </Label>
                <CheckboxInput
                  type="checkbox"
                  checked={possuiRegistroDasInspecoes}
                  onChange={(e) =>
                    setPossuiRegistroDasInspecoes(e.target.checked)
                  }
                />
                Sim
              </FormGroup>

              {possuiRegistroDasInspecoes && (
                <FormGroup>
                  <FormGroup>
                    <Label>Upload do registro</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Acrescentar mais registro</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                </FormGroup>
              )}
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              Realiza supervisão da execução das intervenções profissionais nas
              diversas atividades e programas do estabelecimento?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={realizaSupervisaoExecu}
              onChange={(e) => setRealizaSupervisaoExecu(e.target.checked)}
            />
            Sim
          </FormGroup>

          {realizaSupervisaoExecu && (
            <FormGroup>
              <FormGroup>
                <Label>Com qual periodicidade?</Label>
                <Select
                  value={quantosEstagiariosExtra}
                  onChange={(e) => setQuantosEstagiariosExtra(e.target.value)}
                  customWidth="14.28vw"
                >
                  <option value="" selected disabled>
                    Selecione
                  </option>
                  <option value="Diariamente">Diariamente</option>
                  <option value="Semanalmente">Semanalmente</option>
                  <option value="Quinzenalmente">Quinzenalmente</option>
                  <option value="Mensalmente">Mensalmente</option>
                  <option value="De acordo com a demanda">
                    De acordo com a demanda
                  </option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>
                  Possui um registro das supervisões da execução das
                  intervenções profissionais nas diversas atividades e programas
                  do estabelecimento?
                </Label>
                <CheckboxInput
                  type="checkbox"
                  checked={possuiRegistroSupervisaoExecu}
                  onChange={(e) =>
                    setPossuiRegistroSupervisaoExecu(e.target.checked)
                  }
                />
                Sim
              </FormGroup>

              {possuiRegistroSupervisaoExecu && (
                <FormGroup>
                  <FormGroup>
                    <Label>Upload do registro</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Acrescentar mais registro</Label>
                    <Input
                      type="file"
                      onChange={(e) => setMeuInss(e.target.value)}
                      customWidth="12vw"
                    />
                  </FormGroup>
                </FormGroup>
              )}
            </FormGroup>
          )}

          <FormGroup>
            <Label>
              O estabelecimento zela pelo fiel cumprimento do Código de Ética do
              Profissional de Educação Física?
            </Label>
            <CheckboxInput
              type="checkbox"
              checked={zelaPeloComprimento}
              onChange={(e) => setZelaPeloComprimento(e.target.checked)}
            />
            Sim
          </FormGroup>

          {zelaPeloComprimento && (
            <FormGroup>
              <FormGroup>
                <Label>quais ações são realizadas?</Label>
                <Input
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  customWidth="25vw"
                />
              </FormGroup>

              <FormGroup>
                <Label>Com qual periodicidade?</Label>
                <Select
                  value={quantosEstagiariosExtra}
                  onChange={(e) => setQuantosEstagiariosExtra(e.target.value)}
                  customWidth="14.28vw"
                >
                  <option value="" selected disabled>
                    Selecione
                  </option>
                  <option value="Diariamente">Diariamente</option>
                  <option value="Semanalmente">Semanalmente</option>
                  <option value="Quinzenalmente">Quinzenalmente</option>
                  <option value="Mensalmente">Mensalmente</option>
                  <option value="De acordo com a demanda">
                    De acordo com a demanda
                  </option>
                </Select>
              </FormGroup>
            </FormGroup>
          )}

          <DivText>
            <ButtonReturn
              customWidth="6vw"
              customHeight="4vh"
              onClick={() => navigate("/plataforma/")}
            >
              Voltar
            </ButtonReturn>

            <ButtonSearch customWidth="6vw" customHeight="4vh">
              Enviar
            </ButtonSearch>
          </DivText>
        </DivContet>
      </ContentWrapper>
    </Container>
  );
};

export default DadosEmpresa;
