import React from "react";

import {
  HeaderComponet,
  HeaderContainer,
  HomeContainer,
  Text,
  TextContainer,
  Image,
  DivImagem,
} from "./styles";
import imageHeader from "../../assets/imageHeader.png";

export const Header = () => {
  return (
    <HomeContainer>
      <HeaderComponet>
        <HeaderContainer>
          <TextContainer>
            <Text>Bem Vindo(a)!</Text>
          </TextContainer>

          <DivImagem>
            <Image src={imageHeader} alt="imageHeader" />
          </DivImagem>
        </HeaderContainer>
      </HeaderComponet>
    </HomeContainer>
  );
};
