const colors = {
  white: "#FFFFFF",
  text: "#f7f7f7",
  black: "#111111",
  primary: "#f0c601",
  secondary: "#1f1087",
  third: "#446ce2",
  fourth: "#a8d3e6",
  inputs: "#E8E8E8",
  gray: "#454652",
  input: "#CBD5E1",
};

export { colors };
