import styled from "styled-components";

import { colors } from "../../theme";

export const Container = styled.div`
  display: flex;
  text-align: justify;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  position: absolute;
  margin-left: 11.05vw;
  text-align: justify;
`;

export const DivText = styled.div`
  color: ${colors.third};
  margin-top: 10vh;
  border-bottom: 1px solid ${colors.third};
  padding-bottom: 10px;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const DivContet = styled.div`
  margin-top: 1vh;
  width: 80vw;
`;

export const DivFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export const FormGroup = styled.div`
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: justify;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: justify;
`;

export const Input = styled.input`
  width: ${(props) => props.customWidth || "20vw"};
  height: 2.5vh;
  border-radius: 5px;
  border: 1px solid ${colors.third};
  text-align: left;

  &::placeholder {
    color: ${colors.third};
    text-align: left;
    margin-left: 20px;
  }
`;

export const Select = styled.select`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.5vh;
  border-radius: 5px;
  border: 1px solid ${colors.third};
  background-color: rgba(
    ${parseInt(colors.third.slice(1, 3), 16)},
    ${parseInt(colors.third.slice(3, 5), 16)},
    ${parseInt(colors.third.slice(5, 7), 16)},
    0.4
  );
  text-align: left;
  border: none;
  border-radius: 5px;

  option {
    color: ${colors.third};
    background-color: ${colors.white};
    font-size: 1.1rem;
  }
`;

export const ButtonSearch = styled.button`
  height: ${(props) => props.customHeight || "2.5vh"};
  width: ${(props) => props.customWidth || "2vw"};
  background-color: ${colors.third};
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 5px;
  justify-content: center;
  font-size: 1.5rem;
  align-items: center;
`;

export const ButtonReturn = styled.button`
  height: ${(props) => props.customHeight || "2.5vh"};
  width: ${(props) => props.customWidth || "2vw"};
  background-color: ${colors.gray};
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 5px;
  justify-content: center;
  font-size: 1.5rem;
  align-items: center;
`;

export const CheckboxInput = styled.input`
  margin-right: 5px;
`;
