import React from "react";
import { useNavigate } from "react-router-dom";
import {
  User,
  Buildings,
  ChartBar,
  UserCircleMinus,
} from "@phosphor-icons/react";
import {
  HomeContainer,
  TextCard,
  Body,
  Card,
  CardContainer,
  DivIcon,
  Card2,
} from "./styles";
import { Header } from "../../components/header";

function Home() {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/dados-pessoais`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/dados-empresa`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/alterar-quadro-tecnico`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/baixa-RT`);
    } else if (cardNumber === 5) {
      navigate(`/plataforma/resultados`);
    }
  };

  return (
    <HomeContainer>
      <Header />
      <Body>
        <CardContainer>
          <Card onClick={() => handleCardClick(1)}>
            <DivIcon>
              <User size={38} />
            </DivIcon>
            <TextCard>Dados Pessoais</TextCard>
          </Card>
          <Card onClick={() => handleCardClick(2)}>
            <DivIcon>
              <Buildings size={38} />
            </DivIcon>
            <TextCard>Dados da Empresa</TextCard>
          </Card>

          <Card2 onClick={() => handleCardClick(3)}>
            <DivIcon>
              <Buildings size={38} />
            </DivIcon>
            <TextCard>Alteração Do Quadro Técnico Da Pessoa Jurídica</TextCard>
          </Card2>
        </CardContainer>
        <CardContainer>
          <Card onClick={() => handleCardClick(4)}>
            <DivIcon>
              <UserCircleMinus size={38} />
            </DivIcon>
            <TextCard>Baixa RT</TextCard>
          </Card>
          <Card onClick={() => handleCardClick(5)}>
            <DivIcon>
              <ChartBar size={38} />
            </DivIcon>
            <TextCard>Resultados</TextCard>
          </Card>
        </CardContainer>
      </Body>
    </HomeContainer>
  );
}

export default Home;
