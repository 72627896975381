import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/home";
import DadosPessoais from "../pages/dadosPessoais";
import DadosEmpresa from "../pages/dadosEmpresa";
import AlteraçãoDadosEmpresa from "../pages/alteraçãoQuadroTecnico";
import Resultados from "../pages/resultados";
import BaixaRT from "../pages/baixaNoExercicioRT";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dados-pessoais" element={<DadosPessoais />} />
      <Route path="/dados-empresa" element={<DadosEmpresa />} />
      <Route
        path="/alterar-quadro-tecnico"
        element={<AlteraçãoDadosEmpresa />}
      />
      <Route path="/resultados" element={<Resultados />} />
      <Route path="/baixa-RT" element={<BaixaRT />} />
    </Routes>
  );
};

export default AppRouter;
