import React, { useState } from "react";
import {
  DivContet,
  DivFlex,
  DivText,
  Input,
  FormGroup,
  Label,
  Container,
  ContentWrapper,
  ButtonSearch,
  ButtonReturn,
  StyledTextArea,
} from "./styles";
import { useNavigate } from "react-router-dom";

const BaixaRT = () => {
  const [nome, setNome] = useState("");

  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");

  const [dataNascimento, setDataNascimento] = useState("");

  const [meuinss, setMeuInss] = useState("");

  const navigate = useNavigate();

  return (
    <Container>
      <ContentWrapper>
        <DivText>
          <h1>Baixa No Exercício Da Função De Responsável Técnico</h1>
        </DivText>

        <DivContet>
          <Label>
            <h3>
              VERACIDADE NA INSERÇÃO DOS DADOS
              <br />
              <br />
              Faz-se importante mencionar que de acordo com o Código Penal -
              Decreto-Lei no 2.848, de 7 de dezembro de 1940, incluído pela Lei
              nº 9.983, de 2000 (Inserção de dados falsos em sistema de
              informações) no artigo 313-A do Código Penal prevê o crime de
              inserção de dados falsos em sistema de informações, também chamado
              de peculato digital. Com pena prevista vai de 2 a 12 anos de
              prisão e multa.
              <br />
              <br />
            </h3>
          </Label>
          <DivFlex>
            <FormGroup>
              <Label>Nome RT:</Label>
              <Input
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                customWidth="25vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Dia Do Término Do Contrato:</Label>
              <Input
                type="date"
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Horário Do Término Do Contrato:</Label>
              <Input
                type="text"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                customWidth="16vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Upload da Baixa do Contrato do RT:</Label>
              <Input
                type="file"
                onChange={(e) => setDataNascimento(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <h2>RESPONSÁVEL TÉCNICO SUBSTITUTO</h2>
          </FormGroup>

          <DivFlex>
            <FormGroup>
              <Label>Nome Do Responsável Técnico:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="19vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Nº Registro CREF15/PI:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="9vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Contato com DDD:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Upload do registro Profissional:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="9vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <h2>
              SOBRE AFASTAMENTOS DA RESPONSABILIDADE TÉCNICA: PREENCHIMENTO DO
              RT
            </h2>
          </FormGroup>

          <FormGroup>
            <Label>
              Comunicação ao CREF correspondente sobre afastamento por até 15
              dias:
            </Label>
            <StyledTextArea />
          </FormGroup>

          <FormGroup>
            <Label>
              Comunicação ao representante legal do estabelecimento sobre
              afastamento por até 60 dias:
            </Label>
            <StyledTextArea />
          </FormGroup>

          <DivText>
            <ButtonReturn
              customWidth="6vw"
              customHeight="4vh"
              onClick={() => navigate("/plataforma/")}
            >
              Voltar
            </ButtonReturn>

            <ButtonSearch customWidth="6vw" customHeight="4vh">
              Enviar
            </ButtonSearch>
          </DivText>
        </DivContet>
      </ContentWrapper>
    </Container>
  );
};

export default BaixaRT;
