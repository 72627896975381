import styled from "styled-components";
import { colors } from "../../theme";

export const TextCard = styled.p`
  font-size: 1.8rem;
  color: ${colors.gray};
  font-weight: 500;
`;

export const Body = styled.div`
  padding: 20px;
  flex-direction: column;
  display: flex;
  margin: 0 13.28vw;

  background-size: cover;
`;

export const GraficContainer = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  border: 1px solid ${colors.third};
  background-color: "#f7f7f7";
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
  width: 13vw;
  height: 17vh;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

export const Card2 = styled.div`
  border: 1px solid ${colors.third};
  background-color: "#f7f7f7";
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
  width: auto;
  height: 17vh;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  gap: 10px;
  text-align: start;
`;

export const DivIcon = styled.div`
  background-color: ${colors.third};
  border: 3px solid ${colors.primary};
  color: ${colors.white};
  border-radius: 5vh;
  width: 62px;
  height: 62px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const HomeContainer = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
  /* background-image: url(${require("../../assets/fundoSiasRt.jpeg")}); */
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
`;
