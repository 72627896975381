import React, { useState } from "react";

import {
  DivContet,
  DivFlex,
  DivText,
  Input,
  Select,
  FormGroup,
  Label,
  CheckboxInput,
  Container,
  ContentWrapper,
  ButtonSearch,
  ButtonReturn,
} from "./styles";
import { useNavigate } from "react-router-dom";

const DadosPessoais = () => {
  const [nome, setNome] = useState("");
  const [profissao, setProfissao] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [logadouro, setLogadouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [uf, setUf] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [meuinss, setMeuInss] = useState("");
  const [possuiResposavel, setPossuiResponsavel] = useState(false);

  const navigate = useNavigate();

  return (
    <Container>
      <ContentWrapper>
        <DivText>
          <h1>Dados Pessoais</h1>
        </DivText>

        <DivContet>
          <h2>
            VERACIDADE NA INSERÇÃO DOS DADOS
            <br />
            <br />
            Faz-se importante mencionar que de acordo com o Código Penal -
            Decreto-Lei no 2.848, de 7 de dezembro de 1940, incluído pela Lei nº
            9.983, de 2000 (Inserção de dados falsos em sistema de informações)
            no artigo 313-A do Código Penal prevê o crime de inserção de dados
            falsos em sistema de informações, também chamado de peculato
            digital. Com pena prevista vai de 2 a 12 anos de prisão e multa.
            <br />
            <br />
          </h2>
          <DivFlex>
            <FormGroup>
              <Label>Nome completo:</Label>
              <Input
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                customWidth="25vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Email:</Label>
              <Input
                type="text"
                value={profissao}
                onChange={(e) => setProfissao(e.target.value)}
                customWidth="16vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Estado Civil:</Label>
              <Select
                value={estadoCivil}
                onChange={(e) => setEstadoCivil(e.target.value)}
                customWidth="9.28vw"
              >
                <option value="" selected disabled>
                  Selecione
                </option>
                <option value="solteiro">Solteiro(a)</option>
                <option value="casado">Casado(a)</option>
                <option value="divorciado">Divorciado(a)</option>
                <option value="viuvo">Viúvo(a)</option>
                <option value="uniaoEstavel">União Estável</option>
              </Select>
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>RG:</Label>
              <Input
                type="text"
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>CPF:</Label>
              <Input
                type="text"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Data de Nascimento:</Label>
              <Input
                type="text"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>CEP:</Label>
              <Input
                type="text"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Logradouro:</Label>
              <Input
                type="text"
                value={logadouro}
                onChange={(e) => setLogadouro(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Complemento:</Label>
              <Input
                type="text"
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Bairro:</Label>
              <Input
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                customWidth="8vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Estado:</Label>
              <Input
                type="text"
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                customWidth="10vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>UF:</Label>
              <Input
                type="text"
                value={uf}
                onChange={(e) => setUf(e.target.value)}
                customWidth="4vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Telefone 01:</Label>
              <Input
                type="text"
                value={telefone1}
                onChange={(e) => setTelefone1(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Telefone 02:</Label>
              <Input
                type="text"
                value={telefone2}
                onChange={(e) => setTelefone2(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>WhatsApp:</Label>
              <Input
                type="text"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="19vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Cidade da Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="14vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Estado da Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="14vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Tipo de Título:</Label>
              <Select
                value={estadoCivil}
                onChange={(e) => setEstadoCivil(e.target.value)}
                customWidth="9.28vw"
              >
                <option value="" selected disabled>
                  Selecione
                </option>
                <option value="bacharelado">Bacharelado</option>
                <option value="licenciatura">Licenciatura</option>
                <option value="bacharelado/licenciatura">
                  Bacharelado/Licenciatura
                </option>
                <option value="Tecnólogo">Tecnólogo</option>
              </Select>
            </FormGroup>
          </DivFlex>

          <DivFlex>
            <FormGroup>
              <Label>Data da Colação de Grau:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Certficado:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <h2>Pós Graduação</h2>

          <DivFlex>
            <FormGroup>
              <Label>Epecialista em (nome do título):</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Nome da Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Ano de Conclusão:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="8vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Certficado:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <h2>Mestrado</h2>

          <DivFlex>
            <FormGroup>
              <Label>Mestre em (nome do título):</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Nome da Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Ano de Conclusão:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="8vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Certficado:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <h2>Doutorado</h2>

          <DivFlex>
            <FormGroup>
              <Label>Doutor em (nome do título):</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Nome da Instituição Formadora:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="13vw"
              />
            </FormGroup>

            <FormGroup>
              <Label>Ano de Conclusão:</Label>
              <Input
                type="text"
                value={meuinss}
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="8vw"
              />
            </FormGroup>
            <FormGroup>
              <Label>Certficado:</Label>
              <Input
                type="file"
                onChange={(e) => setMeuInss(e.target.value)}
                customWidth="12vw"
              />
            </FormGroup>
          </DivFlex>

          <FormGroup>
            <Label>
              Outros vínculos profissionais?
              <CheckboxInput
                type="checkbox"
                checked={possuiResposavel}
                onChange={(e) => setPossuiResponsavel(e.target.checked)}
              />
              Sim
            </Label>
          </FormGroup>

          {possuiResposavel && (
            <DivContet>
              <DivFlex>
                <FormGroup>
                  <Label>Nome da empresa:</Label>
                  <Input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    customWidth="25vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Função:</Label>
                  <Input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    customWidth="25vw"
                  />
                </FormGroup>
              </DivFlex>

              <DivFlex>
                <FormGroup>
                  <Label>CEP:</Label>
                  <Input
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Logradouro:</Label>
                  <Input
                    type="text"
                    value={logadouro}
                    onChange={(e) => setLogadouro(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Complemento:</Label>
                  <Input
                    type="text"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                    customWidth="12vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Bairro:</Label>
                  <Input
                    type="text"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    customWidth="8vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Estado:</Label>
                  <Input
                    type="text"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                    customWidth="10vw"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>UF:</Label>
                  <Input
                    type="text"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    customWidth="4vw"
                  />
                </FormGroup>
              </DivFlex>
            </DivContet>
          )}

          <DivText>
            <ButtonReturn
              customWidth="6vw"
              customHeight="4vh"
              onClick={() => navigate("/plataforma/")}
            >
              Voltar
            </ButtonReturn>

            <ButtonSearch customWidth="6vw" customHeight="4vh">
              Enviar
            </ButtonSearch>
          </DivText>
        </DivContet>
      </ContentWrapper>
    </Container>
  );
};

export default DadosPessoais;
