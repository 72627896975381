import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Chart from "chart.js/auto";
import { colors } from "../../../theme";

const Container = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  margin-left: 11.05vw;
  position: absolute;
`;

const DivText = styled.div`
  color: ${colors.fourth};
  margin-top: 10vh;
  border-bottom: 1px solid ${colors.fourth};
  width: 80vw;
`;

const ChartContainer = styled.canvas`
  width: auto;
  max-height: 400px;
`;

const Resultados4 = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    let myChart;

    const createChart = () => {
      const ctx = chartRef.current.getContext("2d");
      if (myChart) {
        myChart.destroy();
      }

      myChart = new Chart(ctx, {
        type: "polarArea",
        data: {
          labels: ["Sim", "Não"],
          datasets: [
            {
              data: [55, 45],
              backgroundColor: [
                "rgba(75, 192, 192, 0.6)",
                "rgba(255, 99, 132, 0.6)",
              ],
              borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    };

    createChart();

    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, []);

  return (
    <Container>
      <ChartContainer ref={chartRef} />
    </Container>
  );
};

export default Resultados4;
