import styled from "styled-components";
import { colors } from "../../theme";

export const HeaderComponet = styled.header`
  display: flex;
  flex-direction: row;
  color: ${colors.white};
  background-color: ${colors.third};
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Image = styled.img`
  height: 100px;
  width: 200px;
  margin-right: 0px;
`;

export const DivImagem = styled.div`
  justify-content: center;
  align-items: center;
  margin-right: 9vw;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.third};
  justify-content: space-between;
  align-items: center;
  height: 22vh;
  position: relative;
  width: 100vw;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-left: 13.28vw;
`;

export const Text = styled.p`
  font-size: 3.5rem;
  margin: 0;
  font-weight: 600;
`;

export const Text2 = styled.p`
  font-size: 2.3rem;
  margin: 0;
  font-weight: 500;
`;

export const HomeContainer = styled.div`
  background-color: ${colors.white};
`;
